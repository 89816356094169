import React from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import styled from "styled-components";
import numeral from "numeral";

import { useUser } from "../../../contexts/UserContext";
import ProfilePicture from "../../common/ProfilePicture";
import { ucwords } from "../../../lib/stringHelpers";

const WrapperStyled = styled.div`
  padding: 1rem 0;
  padding-bottom: 0;
  border-bottom: 1px solid var(--border-color);

  &:hover {
    background-color: var(--light-secondary-color);
    cursor: pointer;
  }
`;

const JobListItem = ({ jobRequest }) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const renderHelperView = () => {
    let profile = jobRequest.customer.profile || null;

    if (!profile) {
      profile = jobRequest.customer.profiles.find(
        (p) => p._id === jobRequest.requestFor
      );
    }

    return (
      <Col>
        <ProfilePicture
          user={{
            ...jobRequest.customer,
            customer: { ...jobRequest.customer },
          }}
        />
      </Col>
    );
  };

  const renderCustomerView = () => {
    if (jobRequest.helper) {
      return (
        <Col>
          <label className="text-muted d-block">Accepted Helper</label>
          <ProfilePicture
            user={{
              ...jobRequest.helper,
            }}
          />
        </Col>
      );
    }

    return (
      <Col>
        <label className="text-muted">Requsted Helpers</label>
        <Row>
          {jobRequest.requestedHelpers.map((helper, helperIdx) => {
            return (
              <Col xs={3} key={helperIdx}>
                <ProfilePicture
                  user={{
                    ...helper,
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    );
  };

  const getStatusColor = () => {
    switch (jobRequest.jobStatus.toLowerCase()) {
      case "new help request":
        return "info";
      case "booking confirmed":
        return "success";
      case "booking canceled":
        return "warning";
      default:
        return "primary";
    }
  };

  const getPaymentDetails = () => {
    if (!jobRequest.requestedHelperRates) {
      return null;
    }

    let min = 0;
    let max = 0;

    jobRequest.requestedHelperRates.forEach((item) => {
      min = min === 0 || item.helperTotal < min ? item.helperTotal : min;
      max = max === 0 || item.helperTotal > max ? item.helperTotal : max;
    });

    let amount =
      min !== max
        ? `${numeral(min).format("$0,0.00")} - ${numeral(max).format("0,0.00")}`
        : numeral(max).format("$0,0.00");

    if (
      jobRequest.event &&
      (jobRequest.event.holdAmount || jobRequest.event.completedAmount)
    ) {
      amount = jobRequest.event.completedAmount
        ? numeral(jobRequest.event.completedAmount).format("$0,0.00")
        : numeral(jobRequest.event.holdAmount).format("$0,0.00");
    }

    return (
      <Col>
        <label className="text-muted">Amount</label>
        <br />
        <p>{amount}</p>
      </Col>
    );
  };

  const navigateTo = `/bookings/${jobRequest._id}${
    jobRequest.event ? `/${jobRequest.event._id}` : ""
  }`;
  const startDt = moment(
    jobRequest.event
      ? jobRequest.event.startDate
      : jobRequest.dates.startDateTime
  );
  const endDt = jobRequest.event
    ? moment(jobRequest.event.endDate)
    : moment(startDt).add(jobRequest.hours, "hours");

  return (
    <WrapperStyled className="mb-3" onClick={() => navigate(navigateTo)}>
      <Row>
        <Col xs={4}>
          <Badge bg={getStatusColor()}>{ucwords(jobRequest.jobStatus)}</Badge>
          <p>
            {startDt.format("MM/DD h:mm a")} - {endDt.format("h:mm a")}
          </p>
        </Col>
        {getPaymentDetails()}
        <Col>
          <label className="text-muted">Job Type</label>
          <br />
          <p>
            {jobRequest.jobType.join(", ")}
            {jobRequest.isOvernight ? " Overnight" : ""}
          </p>
        </Col>
        {user.userType === "helper" ? renderHelperView() : null}
        {user.userType === "customer" ? renderCustomerView() : null}
      </Row>
    </WrapperStyled>
  );
};

export default JobListItem;
