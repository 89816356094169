import React, { useState } from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import get from "lodash.get";
import Delete from "@mui/icons-material/Delete";

import { ucwords } from "../../../lib/stringHelpers";
import CreditCardForm from "../../common/CreditCardForm";

const PaymentMethods = ({ user, methods }) => {
  const [showCC, setShowCC] = useState(false);

  return (
    <>
      <h3 className="mb-4">Payment Methods</h3>
      <div>
        {methods.map((method, idx) => {
          const defaultPaymentMethod = get(
            user,
            "customer.defaultPaymentMethod",
            null
          );

          return (
            <div
              key={idx}
              className="d-flex justify-content-between align-items-start mb-3 pb-3"
              style={{ borderBottom: "1px solid #ccc" }}
            >
              <div>
                <span className="me-2">
                  {ucwords(method.card.brand)} {method.card.last4}
                </span>
                {method.id === defaultPaymentMethod ? (
                  <Badge bg="secondary" size="sm">
                    Default
                  </Badge>
                ) : null}
              </div>
              <div>
                <Button variant="link" size="sm">
                  <Delete />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <Modal show={showCC} onHide={() => setShowCC(false)}>
        <Modal.Body>
          <CreditCardForm
            buttonLabel="Save Payment Method"
            redirectUrl="/account/recipient-payment-settings"
            onComplete={(details) => console.log("DETAILS: ", details)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentMethods;
